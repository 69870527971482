
.trash-frame {
    box-sizing: content-box;
    position: fixed;
    text-align: center;
    opacity: .65;
    top: 10px;
    right: 29px;
    width: 125px;
    height: 124px;
    border-radius: 2px;
    border: 1px solid lightgrey;
    background-color: var(--primary);
    z-index: -2147483646;
    
    &:hover {
      background-color: var(--tertiary);
    }
    
    &.hovered {
      pointer-events: none;
      background-color: var(--tertiary);
    }
  
    .trash-cont {
      position: absolute;
      height: 60px;
      width: 45px;
      opacity: 1;
      left: 50%;
      top: 50%;
      background-color: var(--primary-dark);
      transform: translateX(-50%) translateY(-50%);
      border: 1px solid lightgrey;
  
      .trash-top {
        display: block;
        margin: 3px 0 1px 1px;
        width: 40px;
      }
  
      .trash-bottom {
        margin: 0 0 0px 1px;
        display: block;
        width: 40px;
      }
  
    }
  
}

// END of document
