.sign-up {
  display: inline-flex;
  flex-direction: column;
  width: 380px;
  margin-left: 50px;

  .title {
    margin: 10px 0px;
  }

  .buttons {
      display: flex;
      justify-content: space-between;
  }
}
