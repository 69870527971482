.header {
    height: 134px;
    width: 100%;
    display: flex;
    position: fixed;
    top: 0;
    pointer-events: none;
    z-index: 2000000000;
    user-select: none;
  
    .logo-container {
      position: relative;
      width: 100%;
      height: 100%;
  
      &:hover {
        .logo {
          opacity: .3;
        }
  
        .option {
          opacity: 1;
        }
  
      }
    }
  
    .logo {
      height: 93px;
      padding: 10px 0px;
      pointer-events: all;
      position: relative;
      transition: opacity .6s;
    }
  
    .user-options {
      width: 124px;
      height: 124px;
      pointer-events: all;
      margin: 10px 10px;
      text-align: center;
      border: 1px solid lightgray;
      background-color: var(--primary);
      border-radius: 2px;
      color: white;
      z-index: 999999999;
  
      .option {
        cursor: pointer;
        position: relative;
        // left: 50%;
        
        bottom: 30px;
        // transform: translateY(-50%), translateX(-50%);
        opacity: 0;
        transition: opacity .6s;
      }
  
      .welcome {
        text-align: center;
        margin-top: 5px;
      }
      
    }
  
    .sign-in-modal-btn {
      margin-top: 0px;
    }
  
    .sign-modal {
      display: none;
      position: absolute;
      width: 1003px;
      height: 630px;
      top: 150px;
      left: 50%;
      transform: translateX(-50%);
      padding: 30px;
      background-color: var(--secondary);
      pointer-events: all;
      z-index: 1000000001;
      border-radius: 2px;
      border: 1px solid var(--primary-dark);
    }
  
  }
  