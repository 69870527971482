// main-board.styles.scss

.board-backing {
  width: 10000px;
  height: 8000px;
  position: absolute;
  left: 0;
  z-index: -2147483647;
  overflow: scroll;
  user-select: none;
  pointer-events: all;
  // background-image: url(https://picsum.photos/1000/600);
    // background-repeat: no-repeat;
    // background-position: center;
  background-image: url(../../assets/graphy-dark.png); // pattern
  background-attachment: local;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-size: repeat;
}

.canvas {
  z-index: -2147483646;
  // width: 10000px;
  // height: 8000px;
  pointer-events: all;
  position: absolute;
  left: 0;
  top: 0px;
  right: 0px;
  bottom: 0px;
  // user-select: none;
}

// END of document
