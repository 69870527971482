// new-note-styles.scss

.note-wrapper {
  position: absolute;
  display: table;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  // resize: both!important;


  .note-base {
    outline: 1px solid black;
    box-shadow: 3px 3px 3px #4a4a4a, -3px -3px 55px -35px #4a4a4a;
    overflow: hidden;
    position: relative;
    white-space: pre-line;
    width: 100%;
    height: 100%;
    box-shadow: 5px 5px 30px 20px rgba(0, 0, 0, 0.1);
    opacity: 1;
    z-index: 1;
    text-align: center;
    font-size: 18px;
    line-height: 1.4;
    color: rgb(123, 0, 0) !important;
    font-weight: 900;
    align-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    resize: both !important;
    display: table-cell;
    vertical-align: middle;
    padding: 15px;

    &.isMat-true {
      align-items: flex-start;
      padding-top: 20px;
      font-size: 24px !important;
      line-height: 1.2;
      border: 3px solid black;
      display: block;
      box-shadow: 3px 3px 15px #4a4a4a, -3px -3px 55px -35px #4a4a4a;
    }
  }


  .note-check {
    position: absolute;
    top: 6px;
    left: 6px;
    display: block;
    height: 16px;
    opacity: .1;
    z-index: 10;
    pointer-events: all;
  }

  .note-menu {
    position: absolute;
    top: 4px;
    right: 4px;
    height: 12px;
    width: 12px;
    pointer-events: all;
    opacity: .1;
    border: 1px solid black;
    border-radius: 100%;
    line-height: .6;
    padding: 2px;
    font-size: 20px;
    display: none;
    z-index: 10;
  }

  &:hover {
    .note-menu {
      display: block;
      background-color: #4a4a4a;
    }
  }

  .note-tray {
    z-index: 1;
    border-radius: 4px;
    position: absolute;
    pointer-events: all;
    top: 0;
    padding: 5px;
    resize: both;
    display: none;

    &.slide-in {
      right: 5px;
    }

    &.slide-out {
      left: 100% !important;
    }

    .tray-text {
      margin: 1px 1px 1px 1px;
      font-size: 15px;
      width: 100%;
      height: 100%;
      opacity: .8;
      white-space: pre-line;
      background-color: rgba(255, 255, 255, .2);
      resize: both;
      display: none;
    }

  }
}

// END of document